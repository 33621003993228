import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  border-top: 2px solid ${props => props.theme.colors.text};
`

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  a {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    padding: 1em;
    text-decoration: none;
    transition: 0.2s;
    max-width: 190px;
    width: 100%;
    text-align: center;
    &:hover {
      opacity: 1;
      background: ${props => props.theme.colors.purple};
    }
  }
`

const PreviousLink = styled(Link)`
  order: 1;
  @media (min-width: ${props => props.theme.responsive.small}) {
    border-left: 2px solid ${props => props.theme.colors.text};
  }
`

const NextLink = styled(Link)`
  order: 2;
  border-left: 2px solid ${props => props.theme.colors.text};
`

const PostLinks = props => {
  return (
    <Wrapper>
      <Box>
        {props.previous && (
          <PreviousLink to={`${props.basePath}/${props.previous.slug}/`}>
            &#8592; Zurück
          </PreviousLink>
        )}
        {props.next && (
          <NextLink to={`${props.basePath}/${props.next.slug}/`}>
            Weiter &#8594;
          </NextLink>
        )}
      </Box>
    </Wrapper>
  )
}

export default PostLinks
