/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import SEO from '../components/SEO'
import styled from '@emotion/styled'
import ContainerHero from '../components/ContainerHero'
import ContainerContent from '../components/ContainerContent'
import BackgroundImage from 'gatsby-background-image'
import ContentPost from '../components/ContentPost'
import RichTextRenderer from '../components/RichTextRenderer'
import Newsletter from '../components/NewsletterPopup.js'

const Hero = styled(BackgroundImage)`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-grow: 1;
  min-height: 200px;
`

const PostContent = styled.div`
max-width: 700px;
  img {
    width: auto !important;
    max-width: 100% !important;
  }
ul li{
list-style: outside disc !important;
margin-left: 15px !important;
}
ul li p{
display: inline;
}
`

const PostFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    padding: 0 60px;
  }

  .contact {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    display: block;
    border: 2px solid ${props => props.theme.colors.purple};
    border-radius: 999px;
    font-size: 1em;
    outline: none;
    cursor: pointer;
    padding: 10px 15px;
    text-decoration: none;
    transition: 0.2s;
    opacity: 1 !important;
    margin: 0.25em 0 0 0.25em;
    &:focus {
      outline: none;
    }
    &:hover {
      background: ${props => props.theme.colors.purple};
    }

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      padding: 10px 15px;
    }
  }
`

const DefaultTag = styled(Link)`
  vertical-align: bottom;
  display: inline-block;
  margin: 0.25em 0.25em 0 0;
  transition: 0.2s;
  background: ${props => props.theme.colors.background};
  padding: 10px 15px;
  border-radius: 2px;
text-transform: capitalize;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  border: 2px solid ${props => props.theme.colors.text};
  opacity: 1 !important;
  &:hover {
    background: ${props => props.theme.colors.purple};
  }
`

const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    metaDescription,
    heroImage,
    publishDate,
    tags,
  } = data.contentfulPost

  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext
  const body = data.contentfulPost.body.json

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }
  return (
    <Layout>
      <Newsletter color="news" />
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : 'Ein EXTENDIT Blogpost.'
        }
        image={ogImage}
      />

      <Container>
        <ContainerHero sx={{ minHeight: 200 }} nobottom>
          <Hero
            Tag="div"
            fluid={heroImage.fluid}
            backgroundColor={`#f2f2f2`}
            title={title}
            id="fullscreenbg"
            role="img"
            aria-label="Fullscreen Background"
          />
        </ContainerHero>
        <ContainerContent>
          <ContentPost published={publishDate} title={title}>
            <PageBody
              css={css`
                border-top: none !important;
              `}
            >
              <PostContent>
                <RichTextRenderer richTextJson={body} />
              </PostContent>
            </PageBody>
            <PostFooter>
              {tags ? (
                <TagList tags={tags} basePath={basePath} />
              ) : (
                <DefaultTag to="/news/">News</DefaultTag>
              )}
              <Link to="/kontakt/" className="contact">
                Schreiben Sie mir
              </Link>
            </PostFooter>
          </ContentPost>
          <PostLinks previous={previous} next={next} basePath={basePath} />
        </ContainerContent>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "DD MM YY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      body {
        json
      }
    }
  }
`

export default PostTemplate
