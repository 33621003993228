import React from 'react'
import styled from '@emotion/styled'

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.responsive.small}) {
    flex-direction: row;
  }
`

const PostLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  border-top: 2px solid ${props => props.theme.colors.text};
  flex: 100%;
  @media (min-width: ${props => props.theme.responsive.small}) {
    flex: 0 0 100px;
    padding: 30px;
    div:nth-of-type(1) {
      font-size: ${props => props.theme.fontSizes[5]}px;
      max-width: 100px !important;
      line-height: 1em;
    }
    div:nth-of-type(2) {
      font-size: ${props => props.theme.fontSizes[3]}px;
    }
  }
  @media (min-width: ${props => props.theme.responsive.medium}) {
    div:nth-of-type(1) {
      font-size: ${props => props.theme.fontSizes[7]}px;
      max-width: 100px !important;
      line-height: 1em;
    }
    div:nth-of-type(2) {
      font-size: ${props => props.theme.fontSizes[4]}px;
    }
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    padding: 30px 60px;
    div:nth-of-type(1) {
      min-width: 150px;
      font-size: ${props => props.theme.fontSizes[9]}px;
      max-width: 100px !important;
      line-height: 1em;
    }
    div:nth-of-type(2) {
      font-size: ${props => props.theme.fontSizes[5]}px;
    }
  }
`

const PostRight = styled.div`
  border-top: 2px solid ${props => props.theme.colors.text};
  flex-grow: 1;

  > div {
    padding: 15px;
  }
  @media (min-width: ${props => props.theme.responsive.medium}) {
    > div {
      padding: 30px;
    }
  }
  h1 {
    padding: 30px 15px 0;
    line-height: 1em;
    font-size: ${props => props.theme.fontSizes[4]}px;
    @media (min-width: ${props => props.theme.responsive.small}) {
      font-size: ${props => props.theme.fontSizes[5]}px;
    }
    @media (min-width: ${props => props.theme.responsive.medium}) {
      font-size: ${props => props.theme.fontSizes[7]}px;
      padding: 30px 30px 0;
    }
    @media (min-width: ${props => props.theme.responsive.large}) {
      font-size: ${props => props.theme.fontSizes[9]}px;
    }
  }
`

const ContentPost = props => {
  return (
    <PostContainer>
      <PostLeft>
        <div>{props.published}</div>
        <div>by EXTENDIT</div>
      </PostLeft>
      <PostRight>
          <h1>{props.title}</h1>
          {props.children}
      </PostRight>
    </PostContainer>
  )
}

export default ContentPost
